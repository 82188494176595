import React from 'react'

import SEO from '../components/seo'

import Layout from '../components/layout'

import PropertyForm from '../components/owner-property-form'

import ProtectedPage from '../components/protected-page'

import DashboardSidebar, {
    SidebarWrapper,
    SidebarProvider
} from '../components/dashboard-sidebar'

export default () => {
    return (
        <SidebarProvider>
            <Layout noFooter noSocialBar noScrollTop noHeader dashboardHeader>
                <SEO
                    title="Submit a property"
                    description={'Property ePortal'}
                />
                <ProtectedPage>
                    <SidebarWrapper>
                        <DashboardSidebar />
                        <PropertyForm />
                    </SidebarWrapper>
                </ProtectedPage>
            </Layout>
        </SidebarProvider>
    )
}
