import React, { useEffect, useState, useMemo, useRef } from 'react'

import Button from '../button'

import styles from './style.module.scss'

import Form from '../form/api-form'

import Input from '../input'

import {
    usePropertyLocations,
    usePropertyTypes,
    useSaveProperty,
    usePropertyStatus,
    useNumberOfChecks,
} from '../hooks'

import Select from '../select-search'

import { get } from 'lodash-es'

import { DashboardBreadcrumb } from '../breadcrumb'

import { navigate } from 'gatsby'

import { transformList } from '../../helpers'

export default () => {
    const { response: locationsResponse } = usePropertyLocations()

    const locations = useMemo(() => transformList(locationsResponse), [
        locationsResponse,
    ])

    const { response: typesResponse } = usePropertyTypes()

    const types = useMemo(() => transformList(typesResponse), [typesResponse])

    const { response: statusResponse } = usePropertyStatus()

    const status = useMemo(() => transformList(statusResponse), [
        statusResponse,
    ])

    const formRef = useRef({})

    const {
        statusCode,
        response: savePropertyResponse,
        loading,
        submit: saveProperty,
    } = useSaveProperty({
        formRef,
    })

    const numberOfChecks = useNumberOfChecks()

    const [statusValue, setStatusValue] = useState({})

    const [
        shouldShowNumberOfChecksSelect,
        setShouldShowNumberOfChecksSelect,
    ] = useState(false)

    useEffect(() => {
        const label = get(statusValue, 'label')

        if (label && label.match(/rent/i)) {
            setShouldShowNumberOfChecksSelect(true)
        } else {
            setShouldShowNumberOfChecksSelect(false)
        }
    }, [statusValue])

    useEffect(() => {
        if (statusCode === 200) {
            navigate('/owner-properties')
        }
    }, [statusCode])

    return (
        <div className={styles.wrapper}>
            <DashboardBreadcrumb
                breadcrumb={[
                    {
                        title: 'Dashboard',
                        link: '/owner-dashboard',
                    },
                    {
                        title: 'Add property',
                    },
                ]}
            />
            <div className={styles.container}>
                <div className="row">
                    <div className="col-xs-12 col-sm-6">
                        <Form
                            ref={formRef}
                            loading={loading}
                            statusCode={statusCode}
                            onSubmit={() => saveProperty()}
                            response={savePropertyResponse}
                            errorsClassName={styles.formErrors}
                        >
                            <h1 className={styles.pageTitle}>Add property</h1>
                            <Input
                                required
                                name="title"
                                label="Marketing title"
                                placeholder="2 Bedroom Apartment Ready to Move In"
                            />
                            <Input
                                required
                                name="content"
                                label="Marketing description"
                                placeholder={`Property Features:
Bedroom: 2
Built up area: 1050 sqft
View: Community
Unfurnished:
Chiller Free
Basement Parking`}
                                type="textarea"
                            />

                            <Input
                                name="permit_number"
                                label="Permit Number"
                                placeholder={`Rera permit number`}
                            />

                            <Select
                                required
                                name="location"
                                label="Location"
                                placeholder="Multiple locations can be selected"
                                options={locations}
                                isMulti
                            />
                            <Select
                                required
                                name="type"
                                label="Type"
                                options={types}
                            />
                            <Select
                                required
                                name="status"
                                label="Status"
                                options={status}
                                onChange={(status) => setStatusValue(status)}
                            />

                            <Input required name="price" label="Price" />

                            {shouldShowNumberOfChecksSelect && (
                                <Select
                                    required
                                    name="number_of_checks"
                                    label="Number of checks"
                                    options={numberOfChecks}
                                />
                            )}

                            <Input required name="size" label="Size (sqft)" />

                            <Input
                                required
                                name="number_of_rooms"
                                label="Number of bedrooms"
                                required
                            />

                            <Input
                                required
                                label="Title deed"
                                type="file"
                                name="title_deed"
                            />
                            <Input
                                required
                                label="Property images"
                                type="file"
                                name="images[]"
                                multiple
                            />
                            <Button className={styles.btn}>Submit</Button>
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    )
}
